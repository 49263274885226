.page.landing {
    .hero {
        .subtitle {
            font-size: $size-large;
            margin-top: 3rem !important;
        }
    }

    .hero.top {
        background: linear-gradient(135deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 161, 0.6)),
            url('/img/landing.jpg');
        background-size: cover;
        position: relative;

        .title,
        .subtitle {
            color: white;
        }

        .subtitle {
            margin-top: 1rem !important;
            color: $grey-lighter;
        }

        .credit {
            position: absolute;
            right: $pad-normal;
            bottom: $pad-normal;
            color: $grey-lighter;
            font-size: $size-x-small;
            text-align: right;
            opacity: 0.5;

            a {
                color: inherit;
                border-bottom: solid 1px rgba($grey-lighter, 0.5);
                margin: 0 $pad-small;
            }
        }
    }
}

.flex {
    display: flex;

    &.center {
        align-items: center;
        justify-content: center;
    }
}

.button-area {
    padding: 20vh;
    margin-top: -10vh;

    .button {
        width: 50%;
    }

    .main-button {
        // min-width: 30vw;
        height: 10vh;

        .icon {
            margin-right: 0 !important;
        }
    }
}

.usage {
    padding: $pad-large;
    font-size: $size-large * 1.3;

    .step {
        padding: $pad-large 0;
        font-size: $size-large;
        position: relative;
        margin-left: 1rem;
    }

    ol {
        list-style: none;
        counter-reset: list_count;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        li {
            margin-right: 2rem;

            &:before {
                margin-right: 0.25em;
                counter-increment: list_count;
                content: counter(list_count);
                display: inline-block;
                line-height: $size-large;
                text-align: center;
                height: 1.8rem;
                width: 1.8rem;
                padding: 0.15rem;
                border-radius: 50%;
                font-size: 1rem;
                border: 1px solid $black-bis;
                background: $black-bis;
                color: white;
                position: absolute;
                left: -2.5rem;
            }
        }
    }
}