/* Font */
@font-face {
    font-family: 'Noto Sans JP';
    font-display: swap;
    font-weight: 400, 700;
    src: local("Noto Sans CJK JP"),
        local("Noto Sans JP"),
        url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format('woff2'),
        url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format('woff'),
        url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format('opentype');
}


/* Gloabls */
@import "vars.scss";

/* Framework */
@import "~bulma/bulma.sass";
@import "~bulma-tooltip";
@import "~bulma-switch";
@import "~draft-js/dist/Draft.css";
@import "~prismjs/themes/prism.css";
@import '~draft-js-focus-plugin/lib/plugin.css';
@import '~draft-js-divider-plugin/lib/plugin.css';
@import '~draft-js-side-toolbar-plugin/lib/plugin.css';
@import '~draft-js-inline-toolbar-plugin/lib/plugin.css';
@import '~draft-js-anchor-plugin/lib/plugin.css';
@import '~draft-js-image-plugin/lib/plugin.css';
@import "~animate.css/animate.css";

/* modules */
@import "./page/landing";
@import "./page/home";
@import "./page/edit";
@import "./page/share";
@import "./ui/navigation";
@import "./ui/ui";
@import "./ui/card";
@import "./components/pincreation";
@import "./components/boardcreation";

/* Print CSS */
@import "_print.scss";

/* Container */
body {
    font-family: "Noto Sans JP" !important;
    margin: 0;
}

.main-content {
    height: 100vh;
    line-height: 1.8;
    letter-spacing: 1px;
    font-size: $size-medium;
    color: $black;

    ul {
        margin-left: 5px;

        ul {
            margin-left: 30px;
        }
    }

    ol {
        margin-left: calc(5px + 1px);
        counter-reset: section;

        >li {
            list-style: none;

            &:before {
                content: counters(section, '.') '. ';
                counter-increment: section;
            }
        }
    }

    li+li {
        margin-top: 2px;
    }


    img {
        max-height: 85vh;
        box-shadow: $box-shadow-deep;
    }
}

hr {
    margin: $pad-medium $pad-small;
    margin-left: 0;
}

.animatedswitch {
    position: relative;
    width: 100%;
}

.animatedswitch>div {
    position: absolute;
    width: 100%;
}

.overlay {
    position: fixed;
    top: 0px;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background: transparent;
    display: flex;
    transition: all .3s;
    pointer-events: none;
    z-index: $z-index-overlay;

    &.bg {
        background: rgb(255, 255, 255);
        transition: all 1s;
    }

    &.loader-container {
        z-index: $z-index-loader;
    }
}

/* responsive */
.is-7split {
    width: 14.2857% !important;
}

.is-8split {
    width: 12.5% !important;
}