.editor {
    padding: 0 $pad-x-huge;
    max-width: 100vw !important;
    overflow-x: hidden;

    .back-button {
        position: absolute;
        left: $pad-large;
        top: #{$pad-large};
        cursor: pointer;
        color: $black-ter;

        &:hover {
            color: $black;
        }
    }

    .main-content {
        height: auto;
        width: 95%;

        /* Content */
        a {
            color: inherit;
            border-bottom: solid black 1px;
        }

        .a-container {
            max-width: 50vw;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        h1 {
            margin: $pad-large 0 !important;
            margin-top: $pad-huge !important;
            padding-bottom: 10px;
            border-bottom: solid $grey-lighter 1px;

            &:first-child {
                margin-top: 0 !important;
            }
        }

        h2 {
            padding: $pad-small 0;
            padding-bottom: 0;
            margin-top: $pad-x-large !important;
            margin-bottom: $pad-normal;
            display: flex;
            align-items: center;
        }

        h3 {
            padding: $pad-small 0;
            padding-bottom: 0;
            margin-top: $pad-x-large !important;
            margin-bottom: $pad-normal;
            display: flex;
            align-items: center;
            // color: $black-bis;
            position: relative;
            font-size: $size-normal + 0.15rem;
            font-weight: 500;

            &::before {
                content: "";
                width: $pad-x-large;
                height: $pad-x-large;
                border-radius: $pad-xx-large;
                border: solid 1px rgba($grey, 0.1);
                position: absolute;
                // top: -$pad-normal;
                left: -$pad-normal;
            }
        }

        h4 {
            display: flex;
            align-items: center;
            margin-top: $pad-large !important;
            margin-bottom: $pad-normal;

            &::before {
                content: "";
                width: 3px;
                height: 1.2em;
                border-radius: 5px;
                margin-right: $pad-normal;
                // margin-left: $pad-normal;
                background: $grey-lighter;
            }
        }

        blockquote {
            padding: $pad-small $pad-large;
            margin-bottom: 0;

            &+blockquote {
                padding-top: 0;
            }
        }

        blockquote+div {
            margin-top: $pad-medium !important;
        }

        figure {
            text-align: left;
            margin: $pad-medium !important;
            margin-left: 0 !important;
        }

    }

    & .DraftEditor-root>.DraftEditor-editorContainer>div>div {
        margin-bottom: $pad-x-huge
    }

    .content p:not(:last-child) {
        margin-bottom: 0;
    }

    div[contenteditable="true"]:focus {
        outline: none;
    }

    [contentEditable=true]:empty:before {
        content: attr(data-text);
        color: rgba(0, 0, 0, 0.3)
    }

    .header-section {
        font-size: $size-xx-large;
        padding: $pad-large 0;
        padding-top: $pad-xx-large;
        width: 100%;
        display: flex;
        align-items: center;

        .header {
            width: 80%;
        }

        .print-button {
            &:last-child {
                margin-left: 0;
                margin-right: 0;
            }

            margin-right: $pad-normal;
            // width: $pad-x-large;
            // height: $pad-x-large;
            width: 2.2rem;
            height: 2.2rem;
            border-radius: $pad-x-large;
            color: $black-bis;
        }

        .balloon-container {
            position: relative;
            margin-left: auto;
            display: flex;
            align-items: center;

            .balloon {
                position: absolute;
                box-shadow: $box-shadow-deep;
                top: $pad-x-large;
                left: 0;
                z-index: $modal-z;
                background: white;
                border-radius: 5px;
                padding: $pad-medium;
                font-size: $size-normal;

                .row {
                    display: flex;
                    align-items: center;
                    padding: $pad-small 0;

                    >p {
                        min-width: 100px;
                        margin-right: $pad-medium;
                    }

                    >div.field {
                        margin-top: $pad-small;
                    }

                }
            }
        }
    }

    .subtitle-section {
        font-size: $size-medium;
        height: 1.6em;
        line-height: 1.6;
        overflow: hidden;
        color: $black-ter;
        width: 80%;
    }

    .category-input {
        padding: $pad-large 0;
        padding-bottom: 0;
    }

    .main-image {
        padding: $pad-huge 0;
    }

    .main-content {
        padding: $pad-large 0;
    }

    .user-info-container {
        display: flex;
        margin-top: $pad-x-large;

        .user-image {
            height: 45px;
            width: 45px;
            margin-right: 10px;
            border-radius: $radius-rounded;
            overflow: hidden;
        }

        .user-info-detail {
            display: flex;
            font-size: $size-small;

            >* {
                >* {
                    margin-right: 2px;
                }

                margin-right: 5px;
            }
        }

        .user-name {
            cursor: pointer;
            position: relative;

            &:hover {
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: $black-ter;
                }
            }

        }
    }

    /* Plugins */
    div[class^="draftJsToolbar__wrapper"] {
        opacity: 0.4;

        &:hover {
            opacity: 1;
        }
    }

    hr {
        background: white;
        margin: $pad-large 0px;

        &:after {
            font-size: $size-large;
        }
    }


    /* Icon Button */
    .icon-buttons {
        position: fixed;
        right: $pad-x-large;
        bottom: $pad-large;
        display: flex;
        flex-direction: column;
        animation-delay: 0s;

        &>div {
            @extend .icon-buttons
        }

        &>.button {
            width: $pad-xx-large;
            height: $pad-xx-large;
            font-size: $size-medium;
            border-radius: $radius-rounded;
            margin-bottom: $pad-medium;
        }
    }

}


/* Footer */
.editor-footer {
    background: $grey;
    display: flex;
    width: 100%;
    max-width: 100vw;
    z-index: 1000;
    color: white;
    font-size: $size-small;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 10px;

    &>* {
        padding: 0 5px;
    }

    .saving-notification {
        margin-left: auto;
    }
}

.decorator-component {
    display: flex;

    &.card {
        display: inline-block;

        .card-content {
            padding: 0.5rem 3rem;

            .content>p {
                cursor: default;
            }

            ;
        }

    }

    .fade-loader {
        transform: scale(0.5);
        margin-left: -5px;
    }
}


.table-of-contents-container {
    width: $pad-huge * 2.5;
    height: 100%;
    position: fixed;
    pointer-events: none;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
}

.table-of-contents-container {
    z-index: $z-index-5;

}

.table-of-contents {
    padding: $pad-small $pad-medium;
    max-height: 50%;
    overflow-y: auto;
    overflow-x: hidden;
    pointer-events: all;
    border-left: solid 1px rgba($black, 0.2);
    background: rgba(255, 255, 255, 0.9);
    color: $grey;

    &>*:not(.heading) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        max-width: 100%;

        &:hover {
            opacity: 0.8;
            text-decoration: underline;
        }

        &.active {
            color: $info;
        }
    }

    .heading {
        color: $black;
        margin: 0;
        margin-bottom: 10px;
        // position: absolute;
        // left: $pad-medium;
        // top: $pad-normal;
        font-size: $size-normal;
        // display: none;
    }

    h1 {
        font-size: $size-normal;
        margin-top: $pad-normal;
    }

    h2 {
        font-size: $size-small;
        margin-left: $pad-normal;
        margin-top: $pad-small;
    }

    h3 {
        // margin-left: $pad-large;
        font-size: $size-small;
        margin-left: $pad-medium;
        margin-top: $pad-small;
    }

    h4 {
        margin-left: $pad-large;
        // margin-left: $pad-x-large;
        margin-top: 1px;
    }

}


@include mobile {
    .editor {
        padding: 0 $pad-large !important;

        .header-section {
            margin-top: $pad-medium;
            padding-bottom: $pad-normal;

            .header {
                font-size: $size-large;
            }

            .tag {
                font-size: $size-x-small;
            }

            .print-button {
                display: none;
            }
        }

        .main-content {
            font-size: $size-small;

            h1 {
                font-size: $size-large;
            }

            h2 {
                font-size: $size-medium;
            }

            h3 {
                font-size: $size-normal;
            }
        }

        .back-button {
            left: $pad-normal;
        }

    }

    .table-of-contents-container {
        display: none;
    }
}