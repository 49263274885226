.component.my-input {
    border-bottom: solid 1px $black-ter;
    padding: 5px 0;
    width: 70%;
    position: relative;

    &.is-danger {
        border-color: $danger;
    }

    .icon {
        font-size: $size-medium;
        color: $black-ter;
        margin-right: $pad-normal;
    }

    input {
        border: none;
        line-height: $size-large;
        font-size: $size-normal + 0.1rem;
        width: calc(100% - #{$pad-xx-large});

        &:focus {
            outline: 0;
        }
    }

    .help {
        position: absolute;
        margin-top: 10px;
    }

    .delete-button {
        position: absolute;
        right: 0;
        top: $pad-small;
        cursor: pointer;
    }

    @include mobile {
        padding: 0;

        input {
            font-size: $size-small;
        }

        .icon {
            font-size: $size-small;
            margin-right: 0;
        }

    }

}


.component.snack-bar {
    background-color: $black-bis;
    position: fixed;
    border-radius: $radius-large;
    color: white;
    padding: $pad-normal 3rem;
    z-index: $z-index-snackbar;

    &.bottom {
        right: $pad-large;
        bottom: $pad-large;
    }

    &.top {
        top: -$pad-xx-large;
        left: 43%;
    }

    &.error {
        background-color: $danger;
    }
}

.saving-notification {
    .message {
        background: rgba(0, 0, 0, 0)
    }
}