.card {
    border-radius: $radius;
    padding: $pad-normal $pad-medium;
    box-shadow: $box-shadow-small;
    display: flex;
    text-align: left;


    >.title {
        min-width: 90%;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: $pad-large;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        >.title {
            min-width: 90%;
            max-width: 90%;
            font-size: $size-normal;
            text-transform: capitalize;
            margin-left: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        >.subtitle {
            font-size: $size-x-small;
        }


        &.is-large {
            font-size: $size-large * 1.2;
            line-height: $size-large * 1.2
        }
    }

    .subtitle {
        max-width: 95%;
        font-size: $size-small;
        color: $grey-light;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    >.image {
        width: 10%;
        display: flex;
        align-items: center;

        img {
            min-width: 30px;
            min-height: 30px;
        }
    }


    &.clickable {
        cursor: pointer;
        transition: all 0.3s ease-out;
        pointer-events: all;

        &:hover {
            box-shadow: $box-shadow-deep;
            transition: all 0.3s ease-out;
        }

    }

    &:hover {

        .x-mark,
        .pin-mark {
            display: block;
        }
    }


    .x-mark,
    .pin-mark {
        position: absolute;
        right: $pad-normal;
        top: $pad-small;
        display: none;
        pointer-events: all;

        .icon {
            color: $grey-lighter;

            &:hover {
                color: $black-ter;
            }
        }

    }

    .x-mark {
        right: $pad-large +0.3rem;
    }

    .pin-mark {
        &.pinned {
            display: block;

            .icon {
                color: rgba($info, 0.8);
            }
        }

        .icon {
            transform: rotate(20deg);
        }
    }


}