.component.pin-creation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.8);
    z-index: $z-index-overlay;
    padding: $pad-x-huge $pad-large;
    display: flex;
    align-items: center;
    justify-content: center;

    .is-modal {
        width: 60%;
        background: white;
        padding: $pad-xx-large;
        border-radius: $radius;

        >.title {
            font-size: $size-large;
        }

        >.control {
            min-width: 100%;
            padding-top: $pad-large;
            padding-bottom: $pad-large;
            display: flex;
            align-items: center;
            justify-content: center;

            >.input {
                width: 60%;
            }
        }

        >.create-pin {
            margin-top: $pad-medium;
            min-width: 40%;
        }
    }


    .close {
        position: absolute;
        right: $pad-huge;
        top: $pad-huge;
        cursor: pointer;

        .icon {
            color: $grey-lighter;
            font-size: $size-xx-large;
        }

        &:hover {
            .icon {
                color: white;
            }
        }
    }

    &.show {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s;
    }

    &.hidden {
        visibility: hidden;
        opacity: 0;
    }
}