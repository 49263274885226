@media print {
    body {
        width: 1920px !important;
    }

    .animatedswitch>div {
        max-height: 100% !important;

    }

    .editor {
        transform: scale(0.75);
        width: 1400px !important;
        transform-origin: top left;
    }

    img {
        max-width: 100% !important;
        min-width: 0px !important;
    }

    .component.navbar,
    .component.snack-bar,
    .back-button,
    .print-button {
        display: none;
    }

    .table-of-contents-container {
        display: none;
    }

    .comment-area,
    .article-link-area {
        display: none;
    }

    .main-image.image-picker-container,
    .editor-footer,
    .category-input {
        display: none;
    }

}