.page.home {

    .heading {
        width: 100%;
        display: flex;
        align-items: center;
        padding: $pad-xx-large $pad-x-large;


        .board-name {
            width: 20%;
            text-align: right;
            padding-right: $pad-medium;
        }

        >.configration {
            width: 20%;
            text-align: center;
            margin-right: 20px;
            position: relative;

            .icon {
                font-size: $size-large;
                color: $black-ter;
                cursor: pointer;

                &:hover {
                    color: black;
                }
            }

            .badge {
                border-radius: $radius-rounded !important;
                display: flex;
                line-height: 1;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: -$pad-large;
                bottom: -$pad-medium;
                font-size: $size-small;
            }
        }

        >.search-input {
            width: 50%;
            margin-left: 5%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

    }


    .meta-buttons {
        position: fixed;
        right: $pad-x-large;
        bottom: $pad-large;
        display: flex;
        flex-direction: column;

        button {
            width: 60px;
            height: 60px;
            border-radius: 60px;
            margin-bottom: $pad-small;

            &:last-child {
                margin-bottom: 0;
            }

            .icon {
                font-size: $size-large;
                color: $black-ter;
            }

            &:hover {
                .icon {
                    color: $info;
                }

            }
        }
    }

    .pins {
        width: 100%;
        display: flex;
        padding: 0 $pad-x-large;
        margin-top: $pad-medium;

        .cards {
            width: 33.33%;
            padding: 0 $pad-x-large;

            .cards-header {
                padding: 0 $pad-medium;
                display: flex;
                align-items: center;
                margin-bottom: $pad-large + 0.5rem;
                position: relative;
                height: 30px;

                >.title {
                    font-size: $size-medium;
                    margin-bottom: 0;
                }

                >img.pin-icon,
                >img.link-icon,
                >img.document-icon {
                    width: 28px;
                    margin-right: $pad-large;
                }

                img.link-icon {
                    width: 25px;
                }

                >img.add-pin {
                    width: 15px;
                    margin-left: auto;
                    // margin-left: $pad-medium;
                    cursor: pointer;
                }

                &:before {
                    content: "";
                    position: absolute;
                    height: 1px;
                    width: 98%;
                    left: 1%;
                    bottom: -30%;
                    background: $grey;
                    border-radius: $radius;
                }
            }

            .card {
                margin: $pad-normal +0.1rem 0;
                width: 100%;
            }

        }
    }

    @include tablet-only {
        .cards.left {
            display: none;
        }

        .cards.center,
        .cards.right {
            width: 50%;
        }
    }

    @include mobile {
        .heading {
            padding: $pad-large $pad-small;

            .board-name>.subtitle {
                font-size: $size-small;
            }

            .configration>.icon {
                font-size: $size-normal;
            }

            .board-name {
                width: 25%;
            }

            .search-input {
                width: 50%;
            }

            .configration {
                width: 25%;
            }


        }

        .pins {
            padding: $pad-small;
        }

        .cards.left {
            width: 100%;
        }

        .cards.center,
        .cards.right {
            display: none;
        }
    }

}

.page.settings {
    .hero {
        padding-top: $pad-x-large;
    }

    .table {
        padding: $pad-large;
    }

    .columns.heading {
        font-size: $size-large;
        margin-bottom: $pad-medium;
        color: $grey;
    }

    .back-button {
        position: absolute;
        left: $pad-large;
        top: #{$pad-large};
        cursor: pointer;
        color: $black-ter;

        &:hover {
            color: $black;
        }
    }

}