/* Gloabls */
$gap: 100px;

/* Theme Color */
$turquoise: #17a398;
$blue: #174491;
$cyan: #174491ee;
// $cyan: #2dc7ff;
$red: #ff6666;

$blue-rgb: rgb(23, 68, 145);
/* Font Size */
$size-8: 0.7rem;
$size-7: 0.8rem;
$size-6: 0.9rem;
$size-5: 0.95rem;
$size-4: 1.4rem;
$size-3: 1.5rem;
$size-2: 1.6rem;
$size-1: 2rem;
$size-0: 3rem;

$size-x-small: $size-8;
$size-x-large: $size-2;
$size-xx-large: $size-1;

/* Font Weight */
$weight-semibold: 200;
$weight-normal: 250;

/* Text Color */
$black: #373A3C;
$black-bis: #373A3CCC;
$black-ter: #373A3C99;

/* Padding */
$pad-small: 0.35rem;
$pad-normal: 0.65rem;
$pad-medium: 1.1rem;
$pad-large: 1.5rem;
$pad-x-large: 1.8rem;
$pad-xx-large: 3.2rem;
$pad-huge: 5rem;
$pad-x-huge: 9rem;

/* z-index */
$z-index-1: 100;
$z-index-2: 200;
$z-index-3: 300;
$z-index-4: 400;
$z-index-5: 500;
$z-index-6: 600;
$z-index-7: 700;
$z-index-8: 800;
$z-index-9: 900;
/* z-index imprementation */
$z-index-loader: $z-index-4;
$z-index-overlay: $z-index-5;
$z-index-header: $z-index-6;
$modal-z: $z-index-6;
$z-index-snackbar: $z-index-7;

/* shadow */
$box-shadow-small: 0 1px 1px rgba($black-ter, 0.1),
0 0 0 1px rgba($black, 0.1);
$box-shadow-medium: 0 4px 6px rgba($black, 0.1),
0 0 0 1px rgba($black, 0.1);
$box-shadow-deep: 0 6px 8px rgba($black, 0.2),
0 0 0 1px rgba($black, 0.1);