.component.navbar {
    box-shadow: $box-shadow-deep;

    .navbar-item {
        display: flex;
        align-items: center;

        .navbar-link {
            background: $white;
            color: $info;

            .icon {
                margin-right: 0px;
                margin-top: 1px;
            }
        }

        .navbar-dropdown {
            border-top: none;
        }


        &:hover {
            .navbar-dropdown {
                visibility: visible;
                opacity: 1;
                top: 46px;
            }
        }

        .navbar-dropdown {
            transition: all .2s linear !important;
            z-index: -100;
            visibility: hidden;
            display: block;
            opacity: 0;
            top: -100%;
        }

        &.user-icon {
            padding-right: $pad-medium !important;

            img {
                border-radius: 100px;
            }

            i.icon {
                margin-top: 1px;
                margin-right: $pad-small;
                color: $grey;
            }
        }
    }

    @include mobile {
        .navbar-end {
            display: none;
        }
    }

    @include tablet-only {
        .navbar-end {
            display: none;
        }
    }
}